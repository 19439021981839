import * as React from "react";
import { Link } from "gatsby"
import {Container, Navbar, Nav } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Slide } from 'react-toastify';

import "./header.scss"


const Header = () => {
  return (
    <header>
        <ToastContainer
            position="top-center"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            transition={Slide}
            /> 
        <Container>
            <Navbar expand="lg">
                <Link to="/" className="navbar-brand">
                    <div className="logo">
                        <img src="./../images/logo.jpg" alt="logo" />
                    </div>
                </Link>
                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse id="navbarScroll">
                <Nav
                    className="me-auto my-2 my-lg-0"
                    style={{ maxHeight: '100px' }}
                    navbarScroll
                >
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <Nav.Link as={Link} to="/">Home</Nav.Link>
                        </li>
                        <li className="nav-item">
                            <Nav.Link as={Link} to="/about-us">About Us</Nav.Link>
                        </li>
                        <li className="nav-item">
                            <Nav.Link as={Link} to="/services">Services</Nav.Link>
                        </li>
                        {/* <li className="nav-item">
                            <Nav.Link as={Link} to="/news">News</Nav.Link>
                        </li> */}
                        <li className="nav-item">
                            <Nav.Link as={Link} to="/contact">Contact</Nav.Link>
                        </li>
                    </ul>

                    {/* <Nav.Link className="active" href="#action1">Home</Nav.Link>
                    <Nav.Link href="/about-us">About</Nav.Link>
                    <Nav.Link href="#action2">Services</Nav.Link>
                    <Nav.Link href="#action2">News</Nav.Link>
                    <Nav.Link href="#action2">Contact</Nav.Link> */}

                </Nav>

                        <Link className="btn btn-primary" to="/book-appointment">Need a Nurse/Carer</Link>
                {/* <div className="social-media">
                    <ul>
                        <li>
                            <Link to="/">
                                <i className="bi bi-telegram"></i>
                            </Link>
                        </li>
                        <li>
                            <Link to="/">
                                <i className="bi bi-facebook"></i>
                            </Link>
                        </li>
                        <li>
                            <Link to="/">
                                <i className="bi bi-linkedin"></i>
                            </Link>
                        </li>
                        <li>
                            <Link to="/">
                                <i className="bi bi-twitter"></i>
                            </Link>
                        </li>
                    </ul>
                </div> */}
                </Navbar.Collapse>
            </Navbar>
            <div className="logo-content">
                <div className="logo-wrapper">
                    <Link className="logo" to="/">
                        <img src="./../images/logo.jpg" alt="logo"/>
                    </Link>
                </div>
                <div className="d-flex align-items-center">
                    <Link to="tel:+447854 031076" className="call-us">
                        <i className="bi bi-telephone icon"></i>
                        <h5>Call Us</h5>
                        <p>+447854 031076</p>
                    </Link>
                    <div className="opening-hour">
                        <i className="bi bi-clock icon"></i>
                        <h5>opening hours</h5>
                        <p>Mon-Fri: 9:00 A.M - 5:00 PM Sun: Closed</p>
                    </div>
                    {/* <div>
                        <Link className="btn btn-primary" to="/book-appointment">Need a Nurse/Carer</Link>
                    </div> */}
                </div>
            </div>
        </Container>
    </header>
    
  )
}

export default Header;
