import * as React from "react";

import Header from "./../header/header";
import Footer from "./../footer/footer";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";

import "./layout.scss";

const Layout = ({ children }) => {
 
  return (
    <>
      <Header />
        <main>{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
