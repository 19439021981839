import * as React from "react";
import { Container, Row, Col, Form, FormControl } from "react-bootstrap";
import { Link } from "gatsby";
import "./footer.scss";

const Footer = () => {
  return (
    <footer>
      <Container>
        <Row>
          <Col md="6" lg="4">
            <div className="footer-links">
              {/* <h5>Quick Links</h5> */}
              <div className="logo">
                <img src="./../images/logo.jpg" alt="logo" />
              </div>
              {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> */}
              <div className="d-flex flex-column gap-1 ">
                <span>For more details, please contact: </span>
                <ul>
                  <li>
                    <Link to="/" className="d-flex ">
                      <i className="bi bi-geo-alt-fill"></i>{" "}
                      <span className="d-flex flex-column gap-1">
                        <span> Senior Recruitment Officer</span>{" "}
                        <span>First Call Healthcare Services (FHS) UK</span>
                        <span>
                          Saturn Centre, Spring Road, Ettingshall,
                          Wolverhampton, WV4 6JX
                        </span>
                      </span>
                    </Link>
                  </li>
                  <li>
                    <a href="mailto:info@firstcallhealthcareservices.co.uk">
                      {/* <i className="bi bi-envelope-fill"></i>{" "} */}
                      <i class="bi bi-envelope-fill"></i>{" "}
                      info@firstcallhealthcareservices.co.uk
                    </a>
                  </li>
                  <li>
                    <a href="tel:7854 031076" className="inline me-3">
                      <i class="bi bi-phone-fill"></i> +44 7854 031076
                    </a>

                    <a href="tel:1902 494 999" className="inline">
                      <i className="bi bi-telephone-fill"></i> +44 1902 494 999
                    </a>
                    <a href="tel:5603 444 508" className="inline">
                      <i class="bi bi-printer-fill"></i> +44 5603 444 508
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
          <Col md="6" lg="4">
            <div className="footer-links">
              <h5>Quick Links</h5>
              <ul>
                <li>
                  <Link to="/about-us">
                    <i className="bi bi-chevron-right"></i> About Us
                  </Link>
                </li>
                <li>
                  <Link to="/services">
                    <i className="bi bi-chevron-right"></i> Services
                  </Link>
                </li>
                <li>
                  <Link to="/contact">
                    <i className="bi bi-chevron-right"></i> Contact Us
                  </Link>
                </li>
                <li>
                  <Link to="/career">
                    <i className="bi bi-chevron-right"></i> Career
                  </Link>
                </li>
                {/* <li>
                                    <Link to="/privacy-policy"><i className="bi bi-chevron-right"></i> Privacy Policy</Link>
                                </li>
                                <li>
                                    <Link to="/terms-conditions"><i className="bi bi-chevron-right"></i> Terms and Conditions</Link>
                                </li>
                                <li>
                                    <Link to="/faq"><i className="bi bi-chevron-right"></i> Faq</Link>
                                </li> */}
              </ul>
            </div>
          </Col>
          <Col md="6" lg="4">
            <div className="footer-links">
              <h5>Work Hours</h5>
              <div className="timing">
                <i className="bi bi-clock"></i> 9 AM - 5 PM, Monday - Friday
              </div>
              {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> */}
              <a href="tel:+07854031076" className="btn btn-primary mt-4">
                <i className="bi bi-telephone-fill me-1"></i> Call us Today
              </a>
            </div>
          </Col>
          {/* <Col md="6" lg="3">
                        <div className="footer-links">
                            <h5>News Letter</h5>

                        </div>

                        <div className="newsletter">

                            <p className="description">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                            <Form className="news-letter-form">
                                <Form.Group className="mb-3" controlId="formNewsLetter">
                                    <div className="addon-right">
                                        <FormControl
                                            placeholder="Enter email" type="email"
                                        />
                                        <button className="btn-send" type="button">
                                            <i className="bi bi-send"></i>    
                                        </button>
                                    </div>
                                </Form.Group>
                            </Form>
                        </div>
                    </Col> */}
        </Row>
      </Container>

      <div className="copyright">
        <p>
          Copyright @ 2021-2024 firstcall healthcare services. All rights
          reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
